@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

/* Keyframes for animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes backgroundShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Container styles */
.about-container {
  position: relative;
  font-family: 'Nunito', sans-serif;
  background: linear-gradient(135deg, #437a7b, #9d99bb, #76a573, #dae1e7);
  background-size: 300% 300%;
  animation: backgroundShift 10s ease infinite;
  color: #fff;
  overflow: hidden;
  padding: 50px 20px;
  z-index: 1; /* Ensures it is behind navigation */
}

/* Styling for the 'about-me' section */
.about-me {
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.8); /* Increase transparency for better readability */
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  margin: 40px auto;
  max-width: 800px;
  backdrop-filter: blur(5px); /* Blur effect for background */
  border: 1px solid rgba(255, 255, 255, 0.18); /* Subtle border */
}

.about-me h2 {
  text-align: center;
  color: #c69898;
  margin-bottom: 25px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.about-me h2:hover {
  color: #da9e9e; /* Maintains a white color for readability */
  text-shadow: 0 0 10px rgba(255, 255, 255, 1);
}

.about-me p {
  font-size: 1.2em;
  line-height: 1.8;
  color: #c09f9f;
  margin-bottom: 18px;
  animation: fadeIn 2s ease-out forwards;
  opacity: 0; /* Initial state */
  text-shadow: 1px 1px 2px black;
  transform: translateY(20px);
}

/* Hover effects for paragraphs to add interactivity */
.about-me p:hover {
  color: #ffffff; /* Maintains a white color for readability */
  text-shadow: 0 0 10px rgba(255, 255, 255, 1);
}

/* Media queries for responsive adjustments */
@media (max-width: 768px) {
  .about-me {
    padding: 20px;
  }

  .about-me h2 {
    font-size: 1.5rem;
  }

  .about-me p {
    font-size: 1em;
  }
}
