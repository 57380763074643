@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

/* Base Styles for Navigation */
.custom-navigation {
  font-family: 'Poppins', sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  color: white;
  background: linear-gradient(45deg, #1e3c72, #2a5298);
  text-shadow: 1px 1px 2px black;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px 30px; /* Adjusted padding */
  margin: 0 auto;
  overflow: hidden;
  animation: custom-gradientBG 20s ease infinite;
  background-size: 200% 200%;
  transition: background-color 0.3s ease;
}

@keyframes custom-gradientBG {
  0%, 100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.custom-navigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: auto;
  overflow: hidden;
}

.custom-navigation li {
  flex-grow: 1;
  text-align: center;
}

.custom-navigation li a {
  display: block;
  padding: 15px 20px; /* Adjusted padding */
  font-size: 16px; /* Adjusted font size */
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  will-change: transform;
}

.custom-navigation li a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: -1;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.3s ease-out;
}

.custom-navigation li a:hover::before,
.custom-navigation li a:focus::before {
  transform: scaleY(1);
}

.custom-navigation li a:hover,
.custom-navigation li a:focus {
  color: #a8e0ff;
  transform: scale(1.05);
}

/* Responsive Styles */

/* Small screens (max-width: 576px) */
@media (max-width: 576px) {
  .custom-navigation {
    padding: 10px 15px;
  }
  
  .custom-navigation li a {
    padding: 10px 10px;
    font-size: 14px;
  }

  .custom-navigation ul {
    flex-direction: column;
    align-items: flex-start;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
  }

  .custom-navigation ul.show {
    max-height: 1000px; /* Adjust as necessary */
  }

  .custom-navigation .hamburger {
    display: block;
    cursor: pointer;
  }

  .custom-navigation .hamburger div {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 5px 0;
    transition: 0.4s;
  }
}

/* Medium screens (max-width: 768px) */
@media (max-width: 768px) {
  .custom-navigation {
    padding: 10px 20px;
  }
  
  .custom-navigation li a {
    padding: 15px 15px;
    font-size: 16px;
  }

  .custom-navigation ul {
    flex-direction: column;
    align-items: flex-start;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
  }

  .custom-navigation ul.show {
    max-height: 1000px; /* Adjust as necessary */
  }

  .custom-navigation .hamburger {
    display: block;
    cursor: pointer;
  }

  .custom-navigation .hamburger div {
    width: 30px;
    height: 4px;
    background-color: white;
    margin: 6px 0;
    transition: 0.4s;
  }
}

/* Large screens (min-width: 769px) */
@media (min-width: 769px) {
  .custom-navigation {
    padding: 15px 30px; /* Adjust padding for desktop */
  }

  .custom-navigation ul {
    flex-direction: row;
    max-height: none; /* Remove max-height for large screens */
  }

  .custom-navigation li a {
    padding: 20px 15px; /* Adjust padding for desktop */
    font-size: 18px; /* Adjust font size for desktop */
  }
  
  .custom-navigation .hamburger {
    display: none; /* Hide hamburger menu */
  }
}
