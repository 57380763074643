:root {
  --main-bg-color: #B2BDD6; /* Using your original background color for the overall theme */
  --text-primary: #2d3436;
  --text-secondary: #333;
  --accent-color: #4a69bd;
  --hover-accent-color: #3c528b;
}

body {
  font-family: 'Poppins', sans-serif; /* More modern and readable font */
  background-color: var(--main-bg-color);
  margin: 0;
  padding: 0;
  line-height: 1.6; /* Improving readability */
}

section, .main-content {
  padding: 20px;
  text-align: center; /* Focused text alignment for better presentation */
}

section {
  background-color: #fff;
  margin: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadows for depth */
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out; /* Smooth interactions */
}

section:hover {
  transform: translateY(-5px); /* Elevating sections on hover for a dynamic effect */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

.home {
  text-align: center; /* Center alignment for a clean and focused layout */
}

.intro-text, .call-to-action {
  color: var(--text-primary);
  margin: 20px 2%;
}

.intro-text {
  font-size: 1.2em; /* Enhanced font size for better readability */
  max-width: 600px; /* Limiting width for optimal reading */
  margin: auto; /* Centering the text block */
}

.cta-link {
  background-color: var(--accent-color);
  color: white;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition: all 0.3s ease;
}

.cta-link:hover {
  background-color: var(--hover-accent-color);
  transform: scale(1.05); /* Slight increase on hover for emphasis */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adding shadow for depth */
}

@media (max-width: 768px) {
  .home, section, .main-content {
    padding: 15px;
    margin: 10px;
  }

  .intro-text {
    font-size: 1em; /* Smaller font size for mobile devices */
  }
}
