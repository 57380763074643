@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.work-page {
    margin: 60px;
    padding: 20px;
    min-height: 100vh;
    font-family: 'Roboto', sans-serif;
    color: #333;
    animation: fadeIn 1s ease-in-out;
    text-align: center;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.work-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    padding: 20px;
    max-width: 1200px;
    margin: auto;
}

.project {
    background: #fff;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 6px 20px rgba(0,0,0,0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 300px;
    text-align: center;
    position: relative;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.project:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0,0,0,0.3);
}

.project h2 {
    background-color: #007bff;
    color: #fff;
    margin: 0 -20px 10px -20px;
    padding: 20px;
    font-size: 1.6em;
    border-radius: 15px 15px 0 0;
    width: calc(100% + 40px);
    text-align: center;
    transition: background-color 0.3s ease;
}

.project p {
    padding: 10px 20px;
    font-size: 1em;
    line-height: 1.5;
    transition: color 0.3s ease;
}

.project:hover h2 {
    background-color: #0056b3;
}

.project:hover p {
    color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .work-container {
        grid-template-columns: 1fr;
    }
}

@media (prefers-reduced-motion: reduce) {
    .project {
        transition: none;
    }
}
