/* Project.css */
.projects {
    padding: 20px;
    background-color: #f4f4f4;
    margin-top: 100px;
}

.projects h1 {
    text-align: center;
    color: #2a5298; /* Mature blue color */
    margin-bottom: 30px;
}

.project-card {
    background-color: #ffffff;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-left: 4px solid #1e3c72; /* Deep blue accent */
}

.project-card h2 {
    color: #1e3c72;
    margin-bottom: 5px;
}

.project-card h3 {
    color: #555;
    margin-bottom: 10px;
}

.project-card p {
    line-height: 1.6;
    color: #666;
    margin-bottom: 10px;
}

.project-card h4 {
    color: #333;
    margin-bottom: 5px;
}

@media (max-width: 768px) {
    .projects {
        padding: 15px;
    }
}
