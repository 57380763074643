@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.education-section {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.education-block {
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  overflow: hidden;
  transition: transform 0.3s ease, background-color 0.3s ease;
  width: 100%;
  max-width: 800px;
  cursor: pointer;
  position: relative;
}

.education-block::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('Brunel\ University.png'); /* Path to a relevant background image */
  background-size: cover;
  opacity: 0.1;
  z-index: -1;
}

.card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;
}

.card:hover {
  transform: translateY(-5px) scale(1.03);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.card-header {
  background-color: #2a5298;
  color: white;
  padding: 10px 15px;
  transition: background-color 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grade-achieved {
  font-size: 1.1em;
  color: #ffeb3b; /* Use a bright color to highlight grades */
}

.grade {
  font-weight: bold;
  color: #4caf50; /* Brighter color for emphasis */
}

.card-body {
  padding: 10px 15px;
}

.date {
  font-size: 1em;
  color: #666;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .education-section {
    padding: 15px;
  }

  .education-block {
    padding: 15px;
  }

  .card-header, .card-body {
    padding: 10px;
  }
}
