/* Skill.css */
.skills-section {
  padding: 40px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin:  100px 10px 0 10px;
}

.skills-section h2 {
  text-align: center;
  color: #34568B;
  margin-bottom: 30px;
  font-size: 2.5em;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.skills-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 25px;
}

.skill-item {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.skill-item::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
}

.skill-item:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.skill-item:hover {
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.08);
  transform: translateY(-5px);
}

.skill-item h3 {
  color: #2F3C7E;
  margin-bottom: 15px;
  font-size: 1.4em;
}

.skill-item p {
  line-height: 1.7;
  color: #4A4A4A;
  font-size: 1.1em;
}

@media (max-width: 768px) {
  .skills-container {
    grid-template-columns: 1fr;
  }
}
