.contact-section {
  padding: 20px;
  text-align: center;
  margin-top: 100px;
  background: linear-gradient(to right, #6dd5fa, #ff758c); /* Creative gradient background */
}

.contact-section h3 {
  font-size: 35px;
  color: #fff; /* White color for better contrast */
  margin-bottom: 30px;
}

.social-media {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.social-media li {
  display: inline-block;
  margin: 0 20px;
  transition: transform 0.5s ease;
}

.social-icon {
  font-size: 8em; /* Significantly larger icon size */
  color: #fff; /* White icon color */
  transition: transform 0.3s ease, color 0.3s ease;
}

.linkedin:hover {
  transform: scale(1.2); /* Scaling effect on hover */
  color: #0077b5;
}

.github:hover {
  transform: scale(1.2);
  color: #333;
}

.twitter:hover {
  transform: scale(1.2);
  color: #1da1f2;
}

.social-media li:hover {
  transform: translateY(-10px);
}
