@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.footer {
  background: linear-gradient(to right, #2C3E50, #4CA1AF);
  color: #fff;
  text-align: center;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  margin-top: 10px;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
}

.footer-section {
  flex: 1;
  min-width: 250px;
  padding: 10px;
}

.footer-section h2 {
  font-size: 1.4em;
  color: #ffdd59;
  margin-bottom: 15px;
}

.footer-section p, .footer-section ul {
  font-size: 1em;
  line-height: 1.6;
  list-style: none;
  padding: 0;
}

.footer-section ul li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section ul li a:hover, .footer-section a:hover {
  color: #3498DB;
}

.social-icons a {
  font-size: 1.5em;
  margin: 0 10px;
  color: #fff;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #ffdd59;
}

.footer-bottom {
  margin-top: 20px;
  font-size: 0.8em;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-section {
    margin-bottom: 20px;
    text-align: center;
  }

  .social-icons a {
    font-size: 2em;  
  }
}
