.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.5s ease-in-out;
}

.modal-content {
    background: white;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    width: 100%;
    z-index: 1001;
    text-align: left;
    animation: slideIn 0.5s ease-in-out;
    position: relative;
}

.modal-content h2 {
    color: #007bff;
    margin-top: 0;
}

.modal-content p {
    font-size: 1em;
    margin-bottom: 20px;
}

.video-container {
    margin: 20px 0;
}

.close-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: absolute;
    top: 20px;
    right: 20px;
}

.close-btn:hover {
    background-color: #0056b3;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideIn {
    from { transform: translateY(-50px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

/* Additional styles for iframes to ensure they display correctly */
.video-container iframe {
    width: 100%;
    height: 315px; /* Maintain a consistent height */
}

@media (max-width: 768px) {
    .modal-content {
        width: 90%; /* Smaller screens get a more responsive width */
    }
}

@media (prefers-reduced-motion: reduce) {
    * {
        animation: none !important; /* Respect users' preferences for reduced motion */
        transition: none !important;
    }
}
