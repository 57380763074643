@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: linear-gradient(135deg, #1e3c72, #2a5298);
  background-size: 150% 150%;
  color: white;
  margin: 40px 5px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  flex-wrap: wrap;
  transition: all 0.5s ease-out;
  position: relative;
  overflow: hidden;
  margin-top: 100px;
}

.header:hover {
  background: linear-gradient(135deg, #2a5298, #1e3c72);
}

.header-content {
  flex-basis: 100%;
  text-align: center;
  position: relative;
  z-index: 2;
}

.header-content h1 {
  font-size: 2.8em;
  margin-bottom: 0.3em;
  font-weight: bold;
  transition: transform 0.5s ease-out, color 0.5s ease;
  color: #f0f0f0;
}

.header-content h1:hover {
  transform: scale(1.05);
  color: #a8c0ff;
}

.header-content h2 {
  font-size: 1.8em;
  margin-bottom: 1em;
  transition: transform 0.5s ease-out, color 0.5s ease;
}

.header-content h2:hover {
  transform: scale(1.05);
  color: #ccd6f6;
}

.header-content p {
  font-size: 1.2em;
  line-height: 1.8;
  max-width: 800px;
  margin: auto;
  transition: opacity 0.5s ease-in-out;
}

.resume-button {
  background-color: #4a69bd;
  color: white;
  border: none;
  margin-top: 10px;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.resume-button:hover {
  background-color: #3c528b;
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .header {
    margin: 20px 10px;
    padding: 20px;
  }

  .header-content h1 {
    font-size: 2em;
  }

  .header-content h2 {
    font-size: 1.5em;
  }
}
