.error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: radial-gradient(circle, rgba(34,34,34,1) 0%, rgba(17,17,17,1) 100%);
}

.error-content {
    text-align: center;
    color: #ffffff;
    max-width: 600px;
    padding: 20px;
}

.error-animation img {
    width: 100%;
    max-width: 300px;
    animation: floating 5s ease-in-out infinite;
    margin-bottom: 20px;
}

@keyframes floating {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
}

.error-title {
    font-size: 2.2rem;
    color: #f0f0f0;
    margin: 20px 0;
}

.error-message {
    font-size: 1.1rem;
    margin-bottom: 30px;
    color: #d1d1d1;
}

.resume-button {
    background-color: #4a69bd;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.resume-button:hover {
    background-color: #3c528b;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* Responsive Design */
@media (max-width: 768px) {
    .error-content {
        padding: 10px;
    }

    .error-title {
        font-size: 1.8rem;
    }

    .error-message {
        font-size: 1rem;
    }

    .error-animation img {
        max-width: 200px;
    }
}
